import { toast } from 'react-toastify';

const notify = (type, message, component = null) => {
  const tostProp = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  if (component) {
    return toast(component, tostProp);
  }
};

export default notify;
