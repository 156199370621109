import { useEffect, useState, Fragment, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans, useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

import API from '../../api';

import './index.scss';

import { updateLanguage } from '../../actions/Language';
import CheckListItem from '../RecipeAccordion/CheckListItem';
import RetailerContext from '../../context';
import { DialogContent } from '@mui/material';
import { pushToGoogleAnalytics } from '../../utils/google-analytics';

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { retailer } = useContext(RetailerContext);

  const language = useSelector(state => state.language);

  const languages = [
    {
      "code": "en-US",
      "name": "EN"
    },
    {
      "code": "fr-FR",
      "name": "FR"
    },
    {
      "code": "es-ES",
      "name": "ES"
    },
    {
      "code": "zh-CN",
      "name": "CH"
    },
    {
      "code": "tl",
      "name": "TL"
    },
  ];

  const handleListItemClick = (language) => {
    pushToGoogleAnalytics({
      event: 'languageSelection',
      text: language.name,
    });

    dispatch(updateLanguage(language));
    i18n.changeLanguage(language.code.split('-')[0]);
  };

  return (
    <Stack
      direction={'row'}
      spacing={1}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        background: 'transparent',
      }}
    >
      {
        languages.map((languageOption) => (
          <Box
            sx={{
              position: 'relative',
              height: '32px',
              width: '28px',
              cursor: 'pointer'
            }}
            onClick={() => handleListItemClick(languageOption)}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              className='callout'
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                background: '#fff',
                borderBottom: language.code === languageOption.code ? `4px solid ${retailer.brand_color || 'red'}` : '4px solid #000',
                borderRadius: '0'
              }}
            >
              <Typography
                sx={{
                  color: 'black',
                  fontSize: 16,
                  textTransform: 'uppercase',
                  fontWeight: language.code === languageOption.code ? 'bold' : 'normal',

                }}
              >
                {languageOption.name}
              </Typography>
            </Stack>
          </Box>
        ))
      }
    </Stack>
  );
}
